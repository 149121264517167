@import 'css/core.scss';
@import 'css/layout.scss';
@import 'css/utils.scss';
@import 'css/router.scss';



.style_air__menuLeft__logo__1ktQp {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: flex !important;
    align-items: center !important;
}

[data-kit-theme="default"] .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column {
    margin: 0 !important;
}

th.ant-table-cell.ant-table-column-has-sorters {
    line-height: 15px;
    padding-top: 3px !important;
}

span.ant-table-filter-trigger-container {
    display: none !important;
}

[data-kit-theme="default"] .ant-table-thead>tr>th {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1px !important;
}

[data-kit-theme="default"] .ant-table-column-sorters {
    padding: 10px 10px !important;
    padding-top: 13px !important;
}

.air__utils__content {
    padding: 16px !important;
}

.ant-table-tbody>tr>td,
.ant-table-thead>tr>th,
.ant-table tfoot>tr>td,
.ant-table tfoot>tr>th {
    padding: 8px !important;
}

.ant-pagination {
    display: inline-block;
    position: relative;
    top: 11px;
    margin-right: 20px !important;
    margin-left: 20px !important;
}

.pagination-area {
    margin-top: 13px;
}

span.ant-table-filter-column-title {
    padding: 0 !important;
}

.ant-row.ant-form-item.ant-form-item-has-success {
    margin-bottom: 0 !important;
}

.ant-row.ant-form-item.emptyDot {
    margin-bottom: -5px !important;
}

.ant-modal-body {
    padding: 24px;
    font-size: 15px;
    line-height: 1.5715;
    word-wrap: break-word;
}

.ant-modal-footer.actions {
    margin: 0 -24px;
    padding: 20px 24px 0;
    margin-top: 20px;
}

.ant-tabs-tabpane {
    border: 1px solid #f0f0f0;
    padding: 16px;
    margin-top: -16px;
    border-top: 0;
    margin-bottom: 16px;
}

.ant-tabs-nav .ant-tabs-tab {
    margin-right: 0.5rem !important;
}

.ant-row.ant-form-item {
    margin-bottom: 0 !important;
}

.ant-row.ant-form-item.emptyDot .ant-form-item-label-left label {
    display: none;
}

.filterparam {
    float: left;
    max-width: 200px;
    margin-right: 15px;
    display: block;
}

.filterparam.small {
    float: left;
    max-width: 120px;
    margin-right: 15px;
    display: block;
}

.ant-row.ant-form-item.filterparam {
    margin-right: 15px;
    margin-top: 10px;
}

.clearFilters {
    float: left;
    margin-top: 8px;
}

.air__utils__toolbox {
    display: block;
    background: #f2f4f8;
    border: 1px solid #f2f4f8;
    padding: 16px;
    float: left;
    width: 100%;
    padding-top: 8px;
    border-top: 0;
}

.air__utils__content {
    float: left;
    width: 100%;
}

.air__utils__toolbox {
    position: relative;
}

.subbar {
    background: transparent;
    min-height: rem(64);
    border-bottom: 1px solid $border;
    padding: rem(15) rem(20);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
}

.divider {
    width: 1px;
    background-color: $border;
    align-self: stretch;
    flex-grow: 0;
    flex-shrink: 0;
}

.breadcrumbs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: rem(18);
}

.breadcrumb {
    position: relative;
    margin-right: rem(30);
}

.breadcrumb:after {
    content: '-';
    position: absolute;
    right: rem(-18);
    bottom: 0;
}

.breadcrumb:last-child {
    margin-right: 0;
}

.breadcrumb:after {
    display: none;
}

.breadcrumbLink {
    display: block;
}

.breadcrumbLink__current {
    color: '#000';
    font-weight: 700;
    pointer-events: none;
}

.subbar {
    padding: 0px 20px;
    background: #f2f4f8;
    min-height: 0 !important;
    padding-left: 32px;
}

.new-button {
    padding-right: 54px;
}

.air__utils__content {
    padding: 0 !important;
}

.ant-table-wrapper {
    margin: 16px !important;
}

.pagination-area {
    padding-left: 32px;
}

.ant-table-wrapper {
    padding-left: 16px;
    padding-top: 16px;
}

.style_footer__3Rdmb {
    padding: 0;
}

.style_inner__1VCuZ {
    padding-left: 2rem;
}

.clearFilters button {
    padding-right: 1rem;
}

.new-button {
    padding-right: 2.7rem;
}

img.la {
    max-width: 18px;
    position: relative;
    top: -2px;
    border: 0;
}

.air__utils__content2 {
    padding: 2rem !important
}

.table-cell-small .table td,
.table th {
    padding: 0.7rem 0.75rem;
}

.ant-row.ant-form-item.mb-11.ant-form-item-has-success {
    margin-bottom: 10px !important;
}

.customCol {
    padding-left: 5px;
    padding-right: 15px;
}

.customCol:nth-child(1) {
    padding-right: 5px;
}

a.ant-btn.bg-email-btn.ant-btn-link.bg-gray-6 {
    color: #fff !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    height: 31px;
    padding: 3.2px 15px;
    font-size: 15px;
    border-radius: 2px;
    padding-top: 0;
}

[data-kit-theme="default"] .ant-input[disabled].user-disabled {
    background: #eee !important;
    color: #786fa4 !important;
}

.ant-row.ant-form-item.inputsHorizontal.ant-form-item-has-success .ant-form-item-label {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

button.ant-btn.bg-call-btn.bg-success.ant-btn-success i {
    color: #FFF !important;
}

button.ant-btn.bg-call-btn.bg-success.ant-btn-success {
    border: 0 !important;
}

@media(max-width:768px) {
    .subbar .divider.mr-4.d-xl-block {
        display: none;
    }

    .subbar .air__utils__toolbox {
        padding: 0;
    }

    .subbar {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 59px;
        position: relative;
        z-index: 1;
    }

    .subbar .new-button {
        margin: 0 !important;
        padding: .375rem 3.33rem !important;
    }

    .air__utils__toolbox .filterparam {
        max-width: 100%;
        width: 100%;
    }

    .style_topbar__7GIqb {
        z-index: 3;
        position: relative;
    }

    ul.ant-pagination {
        margin: 0 !important;
        margin-bottom: 20px !important;
    }

    .pagination-area {
        margin-bottom: 60px;
    }
}

.logo {
    max-height: 35px !important;
    max-width: 100% !important;
}

.mt-10 {
    margin-top: 7px !important
}

.mt-0 {
    margin-top: 0px !important
}

.ant-tabs-tabpane {
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
}

.card-tabs {
    padding-left: 30px;
    padding-right: 37px;
}

.statistic-tabs .ant-tabs-nav-list .ant-tabs-tab:nth-child(1) .ant-tabs-tab-btn span {
    padding-left: 32px !important;
}

span.ct-label.ct-horizontal.ct-end {
    margin-left: -5px;
}

.date_small .ant-table-thead th.ant-table-column-has-sorters:nth-child(2) {
    width: 150px !important;
}

.item_bottom_margin_fix .ant-col.ant-form-item-label {
    padding-bottom: 0 !important;
}

.ant-row.ant-form-item.phoneText {
    margin-bottom: 15px !important;
}



.calendar {
    height: 900px;
}

.subbar.toolbox2 {
    padding-left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

span.totalMinutes_label {
    font-weight: bold;
}

.totalMinutes {
    margin-left: 30px;
    float: right;
    position: relative;
    top: 3px;
}

span.totalMinutes_value {
    font-weight: bold;
    margin-left: 9px;
    font-size: 24px;
    position: relative;
    top: 2px;
}

.subbar.subbar2we {
    padding: 30px;
}

.subbar.toolbox2 {
    margin-bottom: 0;
}

input.inputStd.editable {
    width: 35px;
    margin: 0;
    padding: 0;
    border: 1px solid #444;
    border-radius: 2px;
}

.timesheet {
    padding: 10px;
    border: 1px solid #bfbfbf;
    margin: 40px;
    width: 1695px;
    border-radius: 2px;
}

input.keinedit {
    background-color: #eee;
    pointer-events: none;
}


input.weekend {
    background-color: rgb(255, 168, 168);
    pointer-events: none;
}


input.inputStd.resultVal {
    width: 74px;
    margin: 0;
    border: 1px solid #444;
    border-radius: 2px;
    text-align: right;
    padding: 0 5px;
    font-weight: bold;
}

input.inputStd.editable {
    padding: 0 5px;
    border-radius: 2px;
    text-align: right;
    font-weight: bold;
}


span.monthHeaderLabel {
    font-weight: bold;
}

span.monthHeaderLabel {
    font-weight: 600;
}

span.month {
    font-weight: bold;
    text-align: center;
    display: block;
}

span.eur,
span.std {
    font-weight: bold;
    font-size: 18px;
    display: block;
    text-align: center;
}

.ant-row.ant-form-item.inputStd.keinedit .ant-input {
    background: #eee;
    pointer-events: none !important;
}

.ant-row.ant-form-item.inputStd.resultVal {
    width: 82px !important;
}

.ant-row.ant-form-item.inputStd.resultVal .ant-input {
    font-weight: bold !important;
    font-size: 14px !important;
    background: #eee;
    pointer-events: none !important;
}

.keinedit .ant-input,
.editable .ant-input {
    font-size: 14px !important;
    font-weight: bold !important;
    width: 44px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
}

span.std.total {
    text-align: right;
}

span.eur.total {
    text-align: right;
}

.ant-row.ant-form-item.inputStd.resultVal .ant-input {
    text-align: right;
}

span.month.summe {
    display: inline;
    position: relative;
    left: -8px;
}

span.eur,
span.std {
    font-size: 17px;
}


.stundenEintragen {
    font-weight: bold;
    font-size: 20px;
    display: block;
    text-align: center;
    padding-bottom: 12px;
}

tr.stundeneintragen td {
    border: 1px solid #111;
}

tr.stundeneintragen td:nth-child(1) {
    border: 0;
}

tr.stundeneintragen td:nth-child(1),
tr.stundeneintragen td:nth-child(33),
tr.stundeneintragen td:nth-child(34) {
    border: 0;
}

.timesheet tr td {
    padding: 0 !important;
}

.keinedit .ant-input,
.editable .ant-input {
    width: 47px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-weight: bold;
    height: 32px;
    padding-right: 0 !important;
}

tr.stundeneintragen td:first-child {
    font-weight: bold;
}

.timesheet table tbody tr:nth-child(1) td {
    border: 1px solid #111;
}

.timesheet table tbody tr:nth-child(1) td:nth-child(1),
.timesheet table tbody tr:nth-child(1) td:nth-child(33),
.timesheet table tbody tr:nth-child(1) td:nth-child(34) {
    border: 0;
}

span.monthHeaderLabel {
    margin-right: 5px;
}

.pageTitleGood {
    font-size: 21px;
}

.timesheet {
    border: 0;
}

span.eur,
span.std {
    height: 40px;
}

.ant-row.ant-form-item.inputStd.resultVal .ant-input {
    font-weight: bold;
    height: 32px;
}

tr.stundeneintragen td:nth-child(33),
tr.stundeneintragen td:nth-child(34) {
    border: 1px solid #000;
}

span.std.total,
span.eur.total {
    padding-top: 7px;
}

tr.nonSelectable td span {
    pointer-events: none !important;
}

span.eur,
span.std {
    padding-top: 6px;
}

.keinedit .ant-input,
.editable .ant-input {
    color: #000 !important;
}

.ant-row.ant-form-item.inputStd.resultVal .ant-input {
    color: #000 !important;
}

iframe.iframe {
    border: 0;
    padding: 40px;
    width: 100%;
    min-height: 960px;
}

.rbc-day-slot.rbc-time-column .rbc-event-label {
    display: none;
}

.rbc-day-slot.rbc-time-column .rbc-event-content span strong {
    display: none;
}

.rbc-day-slot.rbc-time-column .rbc-event {
    width: 40px !important;
}

.woButtons button {
    width: 100% !important;
    margin-top: 10px !important;
    text-align: left !important;
}

.text-dark.font-size-18.font-weight-bold.mb-1 {
    color: #786fa4 !important;
    font-size: 17px !important;
    margin-bottom: 15px !important;
}

.workOrder .cardBody {
    padding: 20px;
}

.toolbox.buttons button,
.toolbox.buttons a {
    width: 200px !important;
    display: inline-block;
    margin-right: 15px;
    text-align: left;
    float: left;
}

.cardBody.notes .ant-col {
    max-width: 100% !important;
}

.customCol .ant-picker {
    width: 100% !important;
}

.table1 {
    white-space: nowrap !important;

    :global(.ant-table-body) {
        overflow-x: auto !important;
    }
}

.lastremove .ant-table-tbody>tr:last-child>td {
    border: 0 !important
}

.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-bookmark {
    border-color: #b53fa7;
    background: #b53fa7;
}

.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-bookmark.r_false:focus,
.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-bookmark.r_false:active {
    background: #702668 !important;
    border-color: #702668 !important;
}

.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-bookmark.r_true {
    background: #3e9cc7 !important;
    border-color: #3e9cc7 !important;
    width: 250px !important;
}

.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-bookmark.r_true:focus,
.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-bookmark.r_true:active {
    background: #3e9cc7 !important;
    border-color: #3e9cc7 !important;
    width: 250px !important;
}


.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-gray-6:focus,
.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-gray-6:focus {
    background: #4f4870 !important;
    border-color: #4f4870 !important;
}

.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-email {
    background: #72a46f !important;
    border-color: #72a46f !important;
    width: 200px !important;
}

.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-email:focus,
.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-email:active {
    background: #385237 !important;
    border-color: #385237 !important;
}

.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-red:focus,
.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-red:active {
    background: #c30000 !important;
    border-color: #c30000 !important;
}

.wordtruncate {
    max-width: 45%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cardBody.totalCard {
    padding: 0;
}

.cardBody.totalCard .ant-input {
    border: 0;
    font-weight: bold;
}

.cardBody.totalCard label {
    padding: 0;
    margin: 0;
}

.card.totals {
    border: 0
}

.card.felds2 .cardBody {
    border: 0 !important;
    padding: 0;
}

.card.felds2 {
    border: 0 !important;
}

.card.felds2 .ant-form-item-label {
    padding: 0;
}

.card.felds2 {
    margin-top: -0.8rem;
}

.artikels .ant-table-wrapper.hidepagination {
    padding-left: 0;
    margin-left: 0;
    margin-top: -10px !important;
}

.artikels thead.ant-table-thead {
    height: 50px !important;
    padding-left: 15px !important;
}

.artikels thead.ant-table-thead tr th,
.artikels tbody.ant-table-tbody tr td {
    padding-left: 15px !important;
}

.artikels tbody.ant-table-tbody tr:last-child td {
    border: 0 !important;
}

.artikels .ant-table-wrapper.hidepagination {
    margin-left: 0px !important;
}

.cardBody.totalCard .ant-input {
    pointer-events: none !important;
    font-size: 18px;
}

.totalAmount .ant-input {
    color: #3e9cc7 !important;
}

.cardBody.totalCard .ant-input {
    position: relative;
    top: -1px;
}

.cardBody.totalCard .ant-col {
    border-bottom: 1px solid #d1d1d1 !important;
    margin-bottom: 1px;
}

.felds2 .ant-row.ant-form-item {
    margin-bottom: -2px !important;
}

.felds2 [data-kit-theme="default"] .ant-form-item-label>label {
    margin-bottom: 0 !important;
}

.felds2 label {
    height: 28px !important;
}

.offerDate .ant-picker {
    width: 100%;
}

span.circlegreen {
    position: relative;
    width: 10px;
    height: 10px;
    background: #1b55e3;
    border-radius: 50%;
    content: '';
    float: left;
}

span.fe.fe-check.green {
    color: green !important;
}

span.fe.fe-check.gri {
    color: #ddd !important;
}

span.fe.fe-check.green,
span.fe.fe-check.gri {
    font-size: 19px !important;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-defualts {
    width: 240px !important;
    background: #e93;
    border-color: #e93;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-defualts:hover,
button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-defualts:focus {
    width: 240px !important;
    background: #cd8229 !important;
    border-color: #9f6623 !important;
}

.things2 {
    padding-top: 30px;
}

.tabsmall {
    margin-left: -30px;
    margin-top: -30px
}

.table1.tabsmall .ant-table-thead {
    display: none !important;
}

.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-email.wi2 {
    width: auto !important;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-close {
    background: #222;
    border-color: #222;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-close:active,
button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-close:focus,
button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-close:hover {
    background: #333 !important;
    border-color: #333 !important;
}


button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-close.status_false {
    background: #6060ff !important;
    border-color: #6060ff !important;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-close.status_false:active,
button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-close.status_false:focus,
button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-close.status_false:hover {
    background: #7373e9 !important;
    border-color: #7373e9 !important;
}

.googlePlacesAutocomplete {
    margin-bottom: 5px;
}

input.disabled {
    background: #f9f9f9 !important;
    pointer-events: none !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
    font-size: 13px;
}

@media(max-width:1400px) {
    .timesheet_overflow {
        overflow-x: auto;
    }
}

.cardBody.totalCard.total2 .ant-col {
    border: 0 !important;
}

.cardBody.totalCard.total2 .ant-input {
    margin-bottom: 5px;
    font-size: 15px;
    padding: 3.2px 11px;
    color: #786fa4;
    font-size: 15px;
    line-height: 1.5715;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #e4e9f0 !important;
    border-radius: 2px;
    transition: all 0.3s;
    pointer-events: all !important;
}

.ant-row.ant-form-item.disabled input {
    color: #c3bedc;
    background-color: #f9f9f9;
    opacity: 1;
    pointer-events: none !important;
}

.clearFilters.ms button {
    display: inline;
    float: left;
    margin-right: 15px;
}

.info2 {
    padding: 15px 45px;
}

.info2.good {
    background: beige;
}

.info2.bad {
    background: #f7cfcb;
}

.tools2 {
    float: left;
    width: 100%;
    margin-top: 22px;
    border-top: 1px solid #ddd;
    padding-top: 16px;
}

p.emailList {
    font-size: 90%;
    margin-top: 15px;
    color: #858585;
}

.ant-row.ant-form-item.inputStd {
    width: 48px;
}

.ant-row.ant-form-item.inputStd.locked .ant-input {
    background-color: #84317f !important;
    pointer-events: none !important;
    width: 100% !important;
    color: #fff !important;
    font-size: 13px !important;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-close.status_true_3 {
    background: #84317f;
    border-color: #84317f;
}

.info2.info3 {
    font-weight: bold;
    color: #84317f;
    text-decoration: underline;
    font-size: 16px !important;
}

.documents .ant-table-wrapper {
    padding: 0;
    margin: 0 !important;
    margin-top: 15px !important;
}

.card.generalInformations.doccard {
    padding: 15px;
}

span.help-block {
    display: block;
    opacity: 0.6;
    padding-top: 5px;
}


a.btn.btn-success.download {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 3.2px 15px;
    font-size: 15px;
    border-radius: 2px;
    background: #19af24;
    top: -2px;
    border-color: #19af24;
    right: 10px;
}

button.ant-btn.ant-btn-warning.secondary_button {
    background: #3eb736 !important;
    border-color: #3eb736 !important;
    color: #fff !important;
}

span.circle {
    display: inline-block;
    position: absolute;
    right: 15px;
    background: #1c1c2b59;
    color: #fff;
    border-radius: 3px;
    width: 42px;
    height: 24px;
    text-align: center;
    padding-top: 3px;
    font-size: 12px;
}

a.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-email.bg-email33 {
    background: #59a3ff !important;
    border-color: #59a3ff !important;
}


.rbc-event {
    background: #1b55e3 !important;
}

.rbc-event-content {
    font-size: 14px !important;
}

span.rbc-toolbar-label {
    font-weight: bold;
}


.tools2.s21 {
    background: #f2f4f8;
    margin-top: -1px;
    padding: 30px 32px;
    padding-top: 20px;
}

.tools2.s21 button {
    width: auto;
    margin-right: 15px !important;
    min-width: 200px;
}

.subbar.toolbox2.t29 {
    padding: 15px 32px;
}

a {
    cursor: pointer;
}

.row.order.deactive {
    position: relative;
}

.row.order.deactive::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #9999992e;
    z-index: 999999;
    content: '';
    cursor: not-allowed !important;
    pointer-events: none !important;
    -webkit-pointer-events: none !important;
}

.row.order.deactive .ant-btn,
.row.order.deactive .ant-form-item-control-input {
    pointer-events: none !important;
    cursor: not-allowed !important;
    -webkit-pointer-events: none !important;
}

.row.order.deactive {
    padding: 0 !important;
    margin: 0 !important;
}

.row.order.deactive .col-xl-12 {
    padding: 0 !important;
}

.cardcontent {
    padding: 30px;
}

.frame2 iframe.iframe {
    padding: 0 !important;
    min-height: 480px !important;
    max-width: 640px !important;
    float: left;
    border: 2px solid #4e4a62;
}

.frame2 .span2 {
    font-size: 15px !important;
    font-weight: bold !important;
    float: left;
}

.col-md-6.frame2 {
    max-height: 964px;
    max-width: 835px;
    padding-bottom: 60px;
}

.frame2 .span2 {
    background: #4e4a62;
    height: 40px;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #000;
    margin-right: 10px;
    color: #fff;
    border-right: 0 !important;
}

.ant-row.ant-form-item.disabled2 input {
    color: #786fa4 !important;
}

a.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.google2 {
    background: #dda734;
}

button.ant-btn.emailbutton {
    background: #1b55e3;
    border: 1px solid #1b55e3;
    color: #fff;
    padding-top: 4px;
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
}

button.ant-btn.emailbutton:hover {
    background: #001b5fd1;
    color: #fff;
    border: 1px solid #1b55e3;
}

.card.notification_custom {
    width: 278px;
    box-shadow: 0px 0px 7px #2121219e;
    background: #1b55e3;
    border: 0 !important;
}

.card.notification_custom div,
.card.notification_custom span,
.card.notification_custom a {
    color: #fff !important;
    cursor: default !important;
}

.card.notification_custom>.title23 {
    color: #fff !important;
    font-size: 17px !important;
    margin-bottom: 15px !important;
}

.close_notification {
    background: transparent !important;
    color: #fff;
    position: absolute;
    right: 0px;
    border: 0 !important;
    top: 0;
    padding: 10px;
    cursor: pointer;
}

a.close_notification {
    cursor: pointer !important;
}

.card.notification_custom a {
    font-size: 15px;
    margin-bottom: 0 !important;
}

.title23 {
    font-size: 15px;
    position: relative;
    top: -6px;
}

.firma_name {
    font-weight: bold;
}

.date_name {
    font-weight: bold;
}

.card.notification_custom .title23 {
    color: powderblue !important;
    width: 111px;
}

.card-body {
    position: relative;
}

#notifications {
    position: fixed;
    bottom: 0;
    right: 15px;
    padding: 20px 0;
    z-index: 45
}

.card.notification_custom {
    margin-bottom: 0.4rem;
    transition: all ease .3s;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-bookmark.companyLogsHistory {
    background-color: black;
    border-color: black;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-bookmark.companyOffersList {
    background-color: #217728 !important;
    border-color: #217728 !important;
}

.compass_grid .ant-table-wrapper {
    padding: 0 !important;
    margin: 0 !important;
}

.compass_grid .pagination-area {
    padding: 0 !important;
}


button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-red.s1 {
    background-color: #007999 !important;
    border-color: #007999 !important;
}

span.monatlicheprice {
    margin-left: 18px;
    color: green;
    font-weight: 600;
}

button.ant-btn.emailbutton.e2 {
    background: #a8a9b0;
    border-color: #a8a9b0;
}

a.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.google2.impressum {
    background: #696dd1;
}

a.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.google2.pvleft {
    background: #e09;
}

a.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.google2.pvright {
    background: #883;
}

.toolbox.buttons a,
.toolbox.buttons button {
    margin-bottom: 5px;
}

.editvalues {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    margin-bottom: 5px;
}

.editvalues span {
    flex: 0.3;
}

.editvalues textarea {
    flex: 0.7;
    border: 1px solid #767676;
    border-radius: 3px;
    padding: 4px 10px;
    height: 30px;
    overflow: hidden;
}

.companyManagers .ant-table-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    margin-top: 15px !important;
    overflow: auto;
}

.companyManagers .ant-table-thead>tr>th {
    padding: 20px 10px !important;
}

.firma_name {
    position: relative;
    top: 0px;
}

a.close_notification {
    z-index: 999;
    position: absolute;
}

.ant-picker-footer {
    display: none;
}

.editorLine .editvalues {
    justify-content: space-between;
}

.editorLine .editvalues input,
.editorLine .editvalues select {
    border: 1px solid;
    padding: 5px 10px;
}

.editorLine .editvalues a {
    border: 1px solid;
    padding: 5px 10px;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-email.bg-email33 {
    background: #939 !important;
    border-color: #939 !important;
    width: 200px !important;
}

.toolbox.buttons.s2s2 button,
.toolbox.buttons.s2s2 a {
    width: 140px !important;
    max-width: 140px !important;
}

.editorLine .editvalues input,
.editorLine .editvalues select,
.editorLine .editvalues a {
    margin-right: 5px !important;
}

.editorLine .editvalues {
    justify-content: flex-start !important;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.new-button {
    width: 200px !important;
    max-width: 200px !important;
}

button.ant-btn.emailbutton.nonblue2 {
    background: #1b55e3;
    color: #fff;
}

button.ant-btn.emailbutton.blue2 {
    background: #fff;
    color: #1b55e3;
}

a.gridButton {
    width: 100%;
    background: #f1f4f8;
    display: inline-block;
    text-align: center;
    max-width: 55px;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 3px;
}



[data-kit-theme="default"] .ant-table-tbody>tr.ant-table-row:focus>td,
[data-kit-theme="default"] .ant-table-tbody>tr.ant-table-row:active>td {
    background: #e4e9f0;
}



span.highlight {
    background: #3556de;
    padding: 5px 10px;
    width: 100%;
    display: block;
    color: #fff;
    border-radius: 3px;
}

span.notj {
    padding: 5px 10px;
    width: 100%;
    display: block;
    border-radius: 3px;
}

span.totalmonatlische {
    margin: 0;
    margin-left: 40px;
    padding-top: 5px;
    display: inline-block;
}

.fill-content {
    display: table;
    width: 100%;
}

.fill-item {
    border-bottom: 1px solid #ddd;
    display: table-row;
    width: 100%;
}

.fill-col {
    border-bottom: 1px solid #bababa;
    display: table-cell;
    width: 100px;
    text-align: left;
}

.fill-header .fill-head:nth-child(1) {
    padding-left: 15px;
}

.fill-item .fill-col:nth-child(1) {
    padding-left: 15px;
}

.flex-fil {
    display: table-row;
}

.fill-header {
    display: table;
    width: 100%;
}

.table-fill {
    /* display: table; */
    /* width: 100%; */
    padding: 40px;
}

.fill-header {
    display: table;
    width: 100%;
}

.fill-head {
    display: table-cell;
    text-align: left;
}


.fill-head,
.fill-col {
    width: 20%;
}

.fill-header .fill-head:nth-child(1),
.fill-item .fill-col:nth-child(1) {
    width: 30%;
}

.fill-header .fill-head:nth-child(5),
.fill-item .fill-col:nth-child(5) {
    width: 10% !important;
}

.fill-header .fill-head:nth-child(4),
.fill-item .fill-col:nth-child(4) {
    width: 7%;
}

.fill-head {
    background: #f5f5f5;
    padding: 10px 0;
    font-weight: bold;
}

.fill-item:nth-child(even) {
    background: #fcfcfc;
}

.fill-item {
    height: 36px;
    vertical-align: middle;
    line-height: 36px;
}

.fill-header .fill-head:nth-child(2),
.fill-item .fill-col:nth-child(2),
.fill-header .fill-head:nth-child(3),
.fill-item .fill-col:nth-child(3) {
    width: 10%;
}

a.fill-button {
    background: #3556de;
    margin-right: 4px;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    width: 32px;
    display: inline-block;
    text-align: center;
    height: 31px;
    padding: 0 !important;
    position: relative;
    top: 3px;
}

a.fill-button span.btn-addon {
    position: relative;
    top: -2px;
}

.fill-item:hover .fill-col {
    background: #eef4ff;
}

.fill-item a.fill-button:nth-child(4) i {
    font-size: 16px !important;
}

.fill-item.updated .fill-col {
    background: #e5e5e5 !important
}

.fill-item.clicked {
    background: #dae6ff;
}

button.ant-btn.secondary_button.ant-btn-success {
    background: #864fff;
    color: #fff;
}

.zip-code-item {
    display: flex;
    justify-content: space-between;
}

span.colEnd {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
}

rte-floatpanel.rte-fixed.rte-floatpanel-paragraphop {
    display: none !important;
}

.datalist {
    padding: 30px;
}

.datalist tr {
    margin-bottom: 5px;
    display: block;
}

.datalist tr i {
    margin-right: 5px;
}

.datalist button {
    border: 0;
}

.path {
    padding: 30px;
    padding-bottom: 0;
    color: red;
    margin-bottom: -15px;
}

.path span {
    font-weight: bold;
}

.air__utils__toolbox_new.folderbuttons button {
    display: inline;
    float: left;
}

.w-tc-editor {
    margin: 30px !important;
    border: 1px solid #ddd;
    margin-top: 0 !important;
}


table.table.ds9 {
    margin: 30px !important;
}

table.table.ds9 th {
    width: 100px !important;
    max-width: 100px !important;
    padding-right: 0 !important;
}

table.table.ds9 tr:nth-child(even) td {
    background: #f9f9f9;
}

table.table.ds9 tr td {
    padding: 5px !important;
}

table.table.ds9 th {
    font-size: 13px;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

table.table.ds9 {
    width: 850px;
    border: 1px solid #dddd;
}

table.table.ds9 th:first-child {
    padding-left: 20px !important;
}

table.table.ds9 tr td:first-child {
    padding-left: 20px !important;
}

table.table.ds9 tr td {
    padding: 0.6rem !important;
    border-right: 1px solid #ddd !important;
    padding-left: 20px !important;
}

table.table.ds9 th {
    padding: 1rem !important;
    padding-bottom: 1rem !important;
    font-size: 14px !important;
    background: #f2f4f8;
    border-right: 1px solid #ddd !important;
}

.buttongroup {
    display: flex;
    padding-top: 30px;
    padding-bottom: 15px;
    border-top: 1px dotted #eddd;
    margin-top: 15px;
    flex-direction: row;
    justify-content: space-between;
}

.buttongroup button {
    flex: 1;
}

.ant-row.ant-form-item.locked .ant-form-item-control-input-content>*,
.ant-row.ant-form-item.locked .ant-select-selector {
    background: #eee !important;
    pointer-events: none !important;
}

.card.generalInformations.doccard {
    box-shadow: none;
    margin-top: 22px;
}

.card.generalInformations.doccard .pagination-area {
    display: none;
}

.ant-collapse.ant-collapse-icon-position-left.ant-collapse-ghost.x2 {
    padding: 0;
}

.x2 .ant-collapse-header {
    margin-left: -15px !important;
}

.card.generalInformations.doccard {
    margin: 0;
}

.buttongroup {
    max-width: 750px;
}

tr.ant-table-row.ant-table-row-level-0.table-row-light td {
    background: #eee;
    opacity: 0.5;
}

@media(max-width: 640px) {
    button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.new-button {
        max-height: 40px !important;
        max-width: 0px !important;
        padding-right: 0 !important;
        width: auto !important;
        margin-right: 0 !important;
    }

}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-email.wi2.createRandom {
    background: #6900ff !important;
    border-color: #6900ff !important;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-email.wi2.createRandom:hover {
    background: #30066b !important;
    border-color: #30066b !important;
}

.ant-popover-content {
    max-width: 70%;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.bg-red.s34 {
    background: #7484d1 !important;
    border-color: #7484d1 !important;
}

.toolbox.buttons button, .toolbox.buttons a {
    width: auto !important;
    min-width: 150px !important;
    max-width: 180px !important;
    float: none !important;
}

.toolbox.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

img.logo {
    height: 50px;
    width: 100px;
}

.ant-row.ant-form-item.weekend .ant-input {
    background: #ffebeb !important
}

.mb-4.air__utils__scrollTable.contract-signed-user {
    max-width: 60%;
}

.col-md-9.buttongroup.newButtonGroup {
    border-top: none !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
}


.status-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.status-icon-svg {
    width: 16px;
    height: 16px;
}

.center-align {
    text-align: center !important;    
  }

